exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-closed-loop-ewallet-solution-js": () => import("./../../../src/pages/closed-loop-ewallet-solution.js" /* webpackChunkName: "component---src-pages-closed-loop-ewallet-solution-js" */),
  "component---src-pages-closed-loop-payment-system-for-auto-fare-js": () => import("./../../../src/pages/closed-loop-payment-system-for-auto-fare.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-auto-fare-js" */),
  "component---src-pages-closed-loop-payment-system-for-education-js": () => import("./../../../src/pages/closed-loop-payment-system-for-education.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-education-js" */),
  "component---src-pages-closed-loop-payment-system-for-event-management-js": () => import("./../../../src/pages/closed-loop-payment-system-for-event-management.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-event-management-js" */),
  "component---src-pages-closed-loop-payment-system-for-food-and-beverage-js": () => import("./../../../src/pages/closed-loop-payment-system-for-food-and-beverage.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-food-and-beverage-js" */),
  "component---src-pages-closed-loop-payment-system-for-hospitality-js": () => import("./../../../src/pages/closed-loop-payment-system-for-hospitality.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-hospitality-js" */),
  "component---src-pages-closed-loop-payment-system-for-parking-js": () => import("./../../../src/pages/closed-loop-payment-system-for-parking.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-parking-js" */),
  "component---src-pages-closed-loop-payment-system-for-retail-js": () => import("./../../../src/pages/closed-loop-payment-system-for-retail.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-retail-js" */),
  "component---src-pages-closed-loop-payment-system-for-toll-js": () => import("./../../../src/pages/closed-loop-payment-system-for-toll.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-toll-js" */),
  "component---src-pages-closed-loop-payment-system-for-transportation-js": () => import("./../../../src/pages/closed-loop-payment-system-for-transportation.js" /* webpackChunkName: "component---src-pages-closed-loop-payment-system-for-transportation-js" */),
  "component---src-pages-closed-loop-prepaid-cards-js": () => import("./../../../src/pages/closed-loop-prepaid-cards.js" /* webpackChunkName: "component---src-pages-closed-loop-prepaid-cards-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-applications-of-closed-loop-payment-systems-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/applications-of-closed-loop-payment-systems/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-applications-of-closed-loop-payment-systems-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-closed-loop-parking-payment-systems-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-closed-loop-parking-payment-systems/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-closed-loop-parking-payment-systems-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choosing-right-closed-loop-wallet-system-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/choosing-right-closed-loop-wallet-system-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choosing-right-closed-loop-wallet-system-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-cards-benefits-and-applications-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/closed-loop-cards-benefits-and-applications/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-cards-benefits-and-applications-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-payment-solutions-for-effective-auto-fare-collection-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/closed-loop-payment-solutions-for-effective-auto-fare-collection/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-payment-solutions-for-effective-auto-fare-collection-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-payment-systems-benefits-examples-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/closed-loop-payment-systems-benefits-examples/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-closed-loop-payment-systems-benefits-examples-index-mdx" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

